<template>
  <GridWithoutAuth>
    <AccountContainer
      title="Login"
      description="Faça o login na sua conta veels"
    >
      <FormKit
        v-slot="{ state }"
        type="form"
        :actions="false"
        form-class="account-form"
        incomplete-message="Por favor, preencha os campos corretamente"
        @submit="accountLoginForm"
      >
        <FormKit
          type="text"
          placeholder="Digite seu e-mail"
          label="E-mail"
          name="email"
          validation="required|email"
          :validation-messages="{
            email: 'Digite um e-mail válido',
            required: 'E-mail obrigatório'
          }"
        />

        <FormKit
          type="password"
          placeholder="Digite sua senha"
          name="password"
          label="Senha"
          validation="required|length:6"
          :validation-messages="{
            length: 'Senha precisa ter no mínimo 6 digitos',
            required: 'Senha obrigatória'
          }"
        />

        <small
          v-if="error"
          class="error-message"
        >
          {{ error }}
        </small>

        <!-- <a
          class="forgot-password"
          @click="$router.push({ name: 'account-recovery' })"
        >Esqueceu a senha?</a> -->

        <div class="actions">
          <ButtonDefault
            class="signup"
            type="button"
            @click="$router.push({ name: 'account-register' })"
          >
            Criar conta
          </ButtonDefault>

          <ButtonDefault
            :loading="state?.loading"
          >
            Fazer login
            <ArrowRightSLine />
          </ButtonDefault>
        </div>
      </FormKit>
    </AccountContainer>
  </GridWithoutAuth>
</template>

<script>
import AccountContainer from '../components/AccountContainer.vue';
import { mapActions } from 'vuex'

export default {
  name: 'AccountLogin',

  components: {
    AccountContainer,
  },
  
  data: () => ({
    error: ''
  }),

  methods: {
    ...mapActions(['accountLogin']),

    async accountLoginForm (data) {
      this.error = "";

      const { error } = await this.accountLogin({
        ...data
      });

      if (error) {
        this.error = 'E-mail ou senha inválido';
        return;
      }

      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss">
.grid-without-auth {
  min-height: 100vh;
  background: $secondary;

  .formkit-form {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .forgot-password {
      text-decoration: underline;
      color: $grayscale-500;
      width: max-content;
      align-self: flex-start;
      font-size: 15px;
      cursor: pointer;
    }

    .actions {
      display: flex;
      flex-flow: nowrap;
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;

      .button-default {
        width: max-content;
        align-self: flex-end;
        width: 200px;

        &.signup {
          background: transparent;
          color: $black-500;
          font-size: 16px;
          justify-content: center;
          width: max-content;
          padding: 0;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>